/**
 * File:ir_list.js - List all the item request
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './ir_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import IrContent from './ir_content';

// Internal Functions

class ItemList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }

  componentDidMount() {}

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      return <IrContent />;
    }
  }
}

export default withRouter(ItemList);
