import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { onDownload } from '../../../common/functions';
import { getDxfFileNameError } from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';

function WireSelectionReviewItem(props) {
  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  const template_label_classes = [];
  const custom_label_classes = [];
  const manual_label_classes = [];
  if (props.show_manual_error) manual_label_classes.push('warning-text');

  if (props.wire.previous === 'template') template_label_classes.push('bold-text');
  else if (props.wire.previous === 'automated_custom') custom_label_classes.push('bold-text');
  else if (props.wire.previous === 'manual_custom') manual_label_classes.push('bold-text');

  const is_universal_recommended = props.wire.recommendation === 'universal_template' || props.wire.universal_arch;
  const is_manual_recommended = props.wire.recommendation === 'manual_custom' && !is_universal_recommended;
  const is_automated_recommended =
    (props.wire.selection_override === 'automated_custom' || (!props.wire.selection_override && props.wire.recommendation === 'automated_custom')) &&
    !is_universal_recommended;
  const wireNameSplitted = props.wire.wire_name.split('_');

  /**
   * Determines whether the Universal Template Wires program is available for the current doctor.
   * @function
   * @returns {boolean} - Indicates whether the Universal Template Wires program is available for the current doctor.
   */
  const isUniversalProgramAvailable = () => {
    return !!props.ws_programs?.find((program) => program.program_id === 'universal_template_wires');
  };

  /**
   * Displays tags for case
   * @function
   * @returns {JSX} - JSX for programs
   */
  const displayTags = (props) => {
    const tag_descriptions = {
      wloop: 'W Loop',
      pusher_loop: 'Pusher Loop',
    };
    const tag_names = {
      wloop: 'w_loop',
      pusher_loop: 'pusher_loop',
    };
    const json_key = `${props.wire.wire_name}_automated-errors`;
    const tag_name_to_id = {
      wloop: 'loki_automated_w_loops',
      pusherloop: 'pusher_loops',
    };

    return props.ws_status_comment_json?.[json_key]?.[0]?.tags.sort().map((tag) => {
      const tag_name = tag_names[tag];
      const should_display = props.ws_programs.some((program) => program.program_id === tag_name_to_id[tag]);
      return should_display ? (
        <div className="wire-selection-review-item__program" key={tag}>
          <input
            type="checkbox"
            name="wire-selection-radio"
            wire={props.wire.wire_name}
            checked={props.wire[tag_name]}
            onChange={() => {
              props.wsToggleSpecial(props.wire.selected, props.wire.wire_name, tag_name, props.wire[tag_name]);
            }}
            disabled={props.wire.selected !== 'manual_custom'}
          />{' '}
          {tag_descriptions[tag]}
        </div>
      ) : null;
    });
  };
  return (
    <div className="wire-selection-review-item">
      <div className="bold-text">{props.wire.type}</div>
      <form className="wire-selection-review-item__radios">
        <div className="bold-text">{props.wire.arch}:</div>
        <div>
          {props.wire.wire_name.includes('specialty') && !props.rework_in_progress ? (
            <label className={template_label_classes.join(' ')}>
              <input type="radio" value="none" name="wire-selection-radio" checked={props.wire.selected === 'none'} onChange={() => props.onChange('none')} />
              None{props.wire.recommendation === 'none' && <i> (Recommended)</i>}
            </label>
          ) : (
            <label className={template_label_classes.join(' ')}>
              <input
                type="radio"
                value="template"
                name="wire-selection-radio"
                checked={props.wire.selected === 'template'}
                onChange={() => props.onChange('template')}
                disabled={props.wire.template_name === null || is_universal_recommended}
              />
              Template Wire: {props.wire.template_name ? props.wire.template_name : 'N/A'}{' '}
              {!is_automated_recommended && !is_manual_recommended && !is_universal_recommended && <i>(Recommended)</i>}
            </label>
          )}
          {isUniversalProgramAvailable() && (
            <label>
              <input
                type="radio"
                value="universal_template"
                name="wire-selection-radio"
                checked={props.wire.selected === 'universal_template'}
                onChange={() => props.onChange('universal_template')}
                disabled={!is_universal_recommended | !isUniversalProgramAvailable()}
              />
              Universal Template
              {!is_automated_recommended && !is_manual_recommended && is_universal_recommended && <i>(Recommended)</i>}
            </label>
          )}

          <label className={custom_label_classes.join(' ')}>
            <input
              type="radio"
              value="automated_custom"
              name="wire-selection-radio"
              checked={props.wire.selected === 'automated_custom'}
              onChange={() => props.onChange('automated_custom')}
              disabled={is_universal_recommended || (props.wire.wire_name.includes('specialty') && props.wire.recommendation === 'none')}
            />
            Automated Custom Wire{is_automated_recommended && !is_manual_recommended && <i> (Recommended)</i>}{' '}
            {props.wire.automated_url && (
              <a className="viewable-text file-span" href={`/${props.wire.automated_url}`} download onClick={onDownload}>
                <i className="fa fa-file-archive-o" />
              </a>
            )}
          </label>

          <label className={manual_label_classes.join(' ')}>
            <input
              type="radio"
              value="manual"
              name="wire-selection-radio"
              checked={props.wire.selected === 'manual_custom'}
              onChange={() => props.onChange('manual_custom')}
            />
            <span>
              {props.wire.manual_url ? (
                <>
                  Manual Custom Wire{is_manual_recommended && <i> (Recommended)</i>} (Uploaded){' '}
                  <a className="viewable-text file-span" href={`/${props.wire.manual_url}`} download onClick={onDownload}>
                    <i className="fa fa-file-archive-o" />
                  </a>
                </>
              ) : (
                <span>Manual Custom Wire{is_manual_recommended && <i> (Recommended)</i>}</span>
              )}{' '}
              <UserPermissionsContext.Consumer>
                {(user_roles_and_permissions) => {
                  const hasPermission = userHasPermission('CASE_MANUAL_LOKI', user_roles_and_permissions.permissions);
                  return (
                    'manual-wire-data' in props.ws_status_comment_json &&
                    hasPermission &&
                    !props.wire.wire_name.includes('specialty') && (
                      <a
                        className="viewable-text"
                        target="_blank"
                        href={`/business/portal/manual-wire/${props.caseId}?arch=${wireNameSplitted[0]}&smartwire=${wireNameSplitted[1]}`}
                      >
                        [Try the new manual wire creation utility]
                      </a>
                    )
                  );
                }}
              </UserPermissionsContext.Consumer>
            </span>{' '}
            {props.wire.manual_url && !props.redo ? (
              <span data-original-title="Remove DXF File" data-toggle="tooltip">
                <i
                  className="fa fa-trash"
                  onClick={() => {
                    props.onRemoveClick('Remove Manual Custom Wire', 'ws_manual', props.wire.manual_url);
                    if (props.setError) {
                      props.setError('');
                    }
                  }}
                />
              </span>
            ) : (
              <span data-original-title="Upload DXF File" data-toggle="tooltip">
                <i
                  className="fa fa-upload"
                  onClick={() => {
                    props.onUploadClick(props.wire.wire_name, 'Manual Custom Wire', 'ws_dxf', props.redo, props.rev);
                    if (props.setError) {
                      props.setError('');
                    }
                  }}
                />
              </span>
            )}
            {displayTags(props)}
          </label>
          {props.dxf_file_name_error.wire_name === props.wire.wire_name && (
            <>
              {props.dxf_file_name_error.case_id ? (
                <div className="dxf-error">Case ID Mismatch</div>
              ) : props.dxf_file_name_error.format ? (
                <div className="dxf-error">Incorrect File Naming Convention</div>
              ) : null}
            </>
          )}
        </div>
      </form>

      {props.wire.template_notes.length > 0 && !props.wire.wire_name.includes('specialty') ? (
        <>
          <div className="bold-text">Template Wire Notes:</div>
          {props.wire.template_notes.map((note, i) => (
            <div key={i}>{note}</div>
          ))}
        </>
      ) : null}

      {props.wire.custom_notes.length > 0 ? (
        <>
          <div className="bold-text">Automated Custom Wire Notes:</div>
          {props.wire.custom_notes.map((note, i) => (
            <div key={i}>{note}</div>
          ))}
        </>
      ) : null}
    </div>
  );
}

WireSelectionReviewItem.propTypes = {
  dxf_file_name_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dxf_file_name_error: getDxfFileNameError(state),
  };
};

export default connect(mapStateToProps, null)(WireSelectionReviewItem);
