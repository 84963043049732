import './photos.scss';
import React, { useContext, useEffect } from 'react';
import { userHasPermission } from '../../common/permission';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction, updateDeleteInProcess } from '../../redux/actions/record_viewer/record_viewer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import AlertDraftReadOnly from '../components/alerts/alert_draft_read_only';
import FileUpload from '../../components/file_upload/file_upload';
import { UserPermissionsContext } from '../../context/user_permission';

function Photos(props) {
  const { permissions } = useContext(UserPermissionsContext);
  const canEdit = userHasPermission('IPP_EDIT', permissions);

  const { photoUpload, buildRecordStates, hide_occlusion } = props;

  useEffect(() => {
    const records = [];
    if (photoUpload) records.push(...photoUpload);
    const options = { teethViewer: false, caseType: 'incomplete', hide_occlusion: hide_occlusion };
    buildRecordStates(records, options);
  }, [photoUpload, buildRecordStates, hide_occlusion]);

  return (
    <form className="case-form-container">
      <h3 id="photoLabel">Upload Photos</h3>
      {props.isDso && props.disabledEdit ? <AlertDraftReadOnly /> : null}

      <FileUpload
        id={props.id}
        filesUploaded={props.photoUpload}
        onUpload={props.onUpload}
        onRemove={props.onRemove}
        onIncompleteSave={props.onIncompleteSave}
        multiple={true}
        location={props.location ? props.location : 'incomplete'}
        folder="photos"
        type="image"
        upload_state={props.upload_state}
        upload_content={props.upload_content}
        show_warning={props.show_warning}
        hide_warning={props.hide_warning}
        removeAllowed={true}
        disabled={props.disabledEdit || !canEdit}
        updateRecordState={props.updateRecordState}
        isLoadingIteroPhotos={props.isLoadingIteroPhotos}
        showRemoveIteroPhotos={props.showRemoveIteroPhotos}
        onRemoveIteroPhotosClick={props.onRemoveIteroPhotosClick}
        isDeletingIteroPhotos={props.isDeletingIteroPhotos}
        iteroPhotosDropzoneMessage={props.iteroPhotosDropzoneMessage}
      />
    </form>
  );
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
      updateDeleteInProcess: updateDeleteInProcess,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
