import {
  SET_DXF_FILE_NAME_ERROR,
  SET_WIRE_MANUFACTURING_STATUS,
  SET_WIRE_MANUFACTURING_STATUS_LOG,
  SET_CUSTOM_WO_FILE_NAME_ERROR,
  SET_WIRE_SELECTION_INFO,
  SET_WIRE_SELECTION_STATUS,
  SET_WS_ASSIGNED_TO,
  SET_WS_GUIDE_FILE_NAME_ERROR,
  SET_WS_PROGRAMS,
} from '../../../actions/bpp/wire_selection/wire_selection';

const initialState = {
  dxf_file_name_error: {
    wire_name: '',
    case_id: false,
    format: false,
  },
  custom_wo_file_name_error: {
    error: false,
    error_type: '',
  },
  upper_zero_arch: false,
  lower_zero_arch: false,
  upper_zero_wire: {},
  lower_zero_wire: {},
  wm_status: '',
  wm_status_log: [],
  ws: [],
  ws_files: [],
  ws_status: '',
  ws_assigned_to: {
    name: 'Unassigned',
    value: '',
  },
  ws_statuses: [],
  ws_wo: '',
  ws_wo_history: [],
  bmf_file: null,
  ws_guide_file_name_error: false,
  ws_programs: [],
  sessionExpire: false,
};

export function wireSelectionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DXF_FILE_NAME_ERROR:
      return {
        ...state,
        dxf_file_name_error: {
          ...state.dxf_file_name_error,
          wire_name: action.wire_name,
          [action.error_type]: action.error,
          [action.error_type === 'case_id' ? 'format' : 'case_id']: false,
        },
      };

    case SET_CUSTOM_WO_FILE_NAME_ERROR:
      return {
        ...state,
        custom_wo_file_name_error: {
          error: action.error,
          error_type: action.error_type,
        },
      };

    case SET_WIRE_MANUFACTURING_STATUS:
      return {
        ...state,
        wm_status: action.wm_status,
      };

    case SET_WIRE_MANUFACTURING_STATUS_LOG:
      return {
        ...state,
        wm_status_log: action.wm_status_log,
      };

    case SET_WIRE_SELECTION_INFO:
      return {
        ...state,
        ws: action.ws,
        ws_files: action.ws_files,
        ws_status: action.ws_status,
        ws_assigned_to: action.ws_assigned_to,
        ws_statuses: action.ws_statuses,
        ws_wo: action.ws_wo,
        ws_wo_history: action.ws_wo_history,
        bmf_file: action.bmf_file,
        ws_temp: action.ws_temp,
        ws_de_selection: action.ws_de_selection,
        ws_status_comment_json: action.ws_status_comment_json,
      };

    case SET_WIRE_SELECTION_STATUS:
      return {
        ...state,
        ws_status: action.ws_status,
      };

    case SET_WS_ASSIGNED_TO:
      return {
        ...state,
        ws_assigned_to: action.ws_assigned_to,
      };

    case SET_WS_GUIDE_FILE_NAME_ERROR:
      return {
        ...state,
        ws_guide_file_name_error: action.error,
      };

    case SET_WS_PROGRAMS:
      return {
        ...state,
        ws_programs: action.ws_programs,
      };

    default:
      return state;
  }
}

export const getDxfFileNameError = (state) => state.wireSelectionReducer.dxf_file_name_error;
export const getCustomWoFileNameError = (state) => state.wireSelectionReducer.custom_wo_file_name_error;
export const getWireManufacturingStatus = (state) => state.wireSelectionReducer.wm_status;
export const getWireManufacturingStatusLog = (state) => state.wireSelectionReducer.wm_status_log;
export const getWireSelectionStatus = (state) => state.wireSelectionReducer.ws_status;
export const getWs = (state) => state.wireSelectionReducer.ws;
export const getWsFiles = (state) => state.wireSelectionReducer.ws_files;
export const getWsAssignedTo = (state) => state.wireSelectionReducer.ws_assigned_to;
export const getWsStatuses = (state) => state.wireSelectionReducer.ws_statuses;
export const getWsWo = (state) => state.wireSelectionReducer.ws_wo;
export const getWsWoHistory = (state) => state.wireSelectionReducer.ws_wo_history;
export const getBmfFile = (state) => state.wireSelectionReducer.bmf_file;
export const getWsTemp = (state) => state.wireSelectionReducer.ws_temp;
export const getWsDeSelection = (state) => state.wireSelectionReducer.ws_de_selection;
export const getWsStatusCommentJson = (state) => state.wireSelectionReducer.ws_status_comment_json;
export const getWsGuideFileNameError = (state) => state.wireSelectionReducer.ws_guide_file_name_error;
export const getWsPrograms = (state) => state.wireSelectionReducer.ws_programs;
