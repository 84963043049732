import './wire_selection_review_confirm.scss';
import React, { useState, useEffect } from 'react';
import { onDownload, removeCaseIdInitialNumber } from '../../../common/functions';
import { custom_part_num } from '../../../business/case_details/template_wire_selection/wire_selection_output';

const getSmartwireNum = (wire) => {
  return wire.wire_name.indexOf('final') >= 0 ? 3 : wire.wire_name.indexOf('intermediate') >= 0 ? 2 : wire.wire_name.indexOf('initial') >= 0 ? 1 : 0;
};

const END_DATE = '2024-03-31T23:59:59';

/**
 * Sorts wires based on type
 * initial -> intermediate -> final
 * Upper before lower
 * @param {Array - Objects} wires
 * @returns {Array} Sorted Wires
 */
const sortWires = (wires) => {
  let sorted = [];
  const order = [
    'upper_specialty',
    'lower_specialty',
    'upper_initial',
    'lower_initial',
    'upper_intermediate',
    'lower_intermediate',
    'upper_final',
    'lower_final',
  ];
  for (const wire_name of order) {
    const index = wires.findIndex((wire) => wire.wire_name === wire_name);
    if (index !== -1) {
      sorted.push(wires[index]);
    }
  }
  return sorted;
};

function WireSelectionReviewConfirm(props) {
  const [showNewIcon, setShowNewIcon] = useState(false);

  const rework_in_progress = props.wire_fabrication_review_status === 'WFR Rework';

  const custom_wires = props.ws.filter((wire) => {
    if (wire.selection_override === 'none' || (wire.selection === 'none' && !wire.selection_override)) return false;
    const default_is_not_template =
      wire.selection_override === null && wire.selection !== 'template' && wire.selection !== 'universal_template' && wire.selection !== 'none';
    const user_choice_is_not_template =
      wire.selection_override !== null && wire.selection_override !== 'template' && wire.selection_override !== 'universal_template';
    const is_correct_arch = props.arch === 'both' || wire.wire_name.indexOf(props.arch) >= 0;

    if ((default_is_not_template || user_choice_is_not_template) && is_correct_arch) {
      if (rework_in_progress) {
        if (wire.status === 'Failure' && wire.comment) {
          return wire.comment.course_of_action === 'Redesign';
        }
        return false;
      }
      return true;
    }

    return false;
  });
  const sorted_wires = sortWires(custom_wires);
  const current_rev = props.current_rev;
  const guide_file = props.ws_files.find((file) => file.file_type === 'ws_guide' && file.upload_data.includes(`/${current_rev}/`));
  const guide_template_file = props.ws_files.find((file) => file.file_type === 'ws_guide_template' && file.upload_data.includes(`/${current_rev}/`));

  const adjust_bmf_file = props.ws_files.findLast((file) => file.file_type === 'ws_bmf' && file.upload_data.includes('.pdf'));
  const adjust_bmf_file_csv = props.ws_files.findLast(
    (file) => file.upload_data.includes(`${props.case_details.case_id}_adjusted_bmf.csv`) && file.file_type === 'ws_bmf_csv'
  );

  const bmf_url = props.bmf_file ? props.bmf_file.url : '';
  const bmf_rev = props.bmf_file ? props.bmf_file.bmf_rev : 'N/A';
  const bmf_csv = adjust_bmf_file_csv ? adjust_bmf_file_csv.upload_data : '';

  const translateWiresName = (wire) => {
    const selected_manual =
      (wire.selection_override && wire.selection_override.indexOf('manual') >= 0) ||
      (wire.selection_override === null && wire.selection.indexOf('manual') >= 0);

    const is_upper = wire.wire_name.indexOf('upper') >= 0;
    const smartwire_num = getSmartwireNum(wire);

    return `${custom_part_num[wire.wire_name]['part_no']} ${is_upper ? 'Upper' : 'Lower'} Smartwire ${smartwire_num} ${
      selected_manual ? '(Manually Uploaded)' : ''
    }`;
  };
  /**
   * Constructs the URL needed for the download
   * @param {String} name - name of the identified wire
   * @param {Object} wire - contains the wire objects
   * @function
   */
  const getURLByWireName = (name, wire) => {
    return name.indexOf('Manual') >= 0 ? wire.case_file_manual__upload_data : wire.case_file__upload_data;
  };

  const has_status_comment_error = props.ws_status_comment && props.ws_status_comment !== 'Success' ? props.ws_status_comment : '';

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    const today = new Date();
    const endDate = new Date(END_DATE);
    setShowNewIcon(today <= endDate);
  }, []);
  return (
    <div className="wire-selection-section">
      <div className="bold-text center-text margin-top-20">
        Wire Selection{props.case_details.ws_rework_no ? ` (Rework ${props.case_details.ws_rework_no})` : null}
      </div>
      <div className="center-text">
        Please review the outputs below. Return to wire selection review to make updates as needed. Click <b>Proceed</b> to begin Custom Wire WO Creation if
        required or complete the process.
      </div>
      {bmf_url && (
        <div className="bmf-link">
          <a className="underline-text viewable-text view-upload" href={`/${bmf_url}`} download onClick={onDownload}>
            Bracket Measurements Form <i className="fa fa-file-excel-o" aria-hidden="true" />
          </a>
          <span>
            (<span className="bold-text">Rev#: </span>
            {bmf_rev})
          </span>
        </div>
      )}
      {adjust_bmf_file && (
        <div className="center-text">
          <span
            className="underline-text viewable-text file-span"
            onClick={() => {
              props.setModal('bmf_pdf', adjust_bmf_file);
            }}
          >
            Adjusted Bracket Measurements Form <i className="fa fa-file-pdf-o" aria-hidden="true" />
          </span>
        </div>
      )}
      {bmf_csv && (
        <div className="center-text">
          <a className="underline-text viewable-text file-span csv-link" href={`/${bmf_csv}`} download onClick={onDownload}>
            Download ABMF CSV
          </a>
          <i className="fa fa-file-excel-o csv-icon-file" aria-hidden="true" />
          {showNewIcon && <span className="new-icon"> New!</span>}
        </div>
      )}
      {has_status_comment_error && !guide_file ? (
        <div className="center-text underline-text margin-top-10">
          <div className="failed-error-message">
            <i className="fa fa-exclamation-circle fa-2x" aria-hidden="true" data-toggle="tooltip" data-placement="top" title={has_status_comment_error}></i>
          </div>
        </div>
      ) : null}

      {guide_template_file && (
        <>
          <div className="center-text margin-top-10">
            <span
              className="underline-text viewable-text file-span"
              onClick={() => {
                props.setModal('ws_pdf', guide_template_file);
              }}
            >
              Wire Selection Guide Template <i className="fa fa-file-pdf-o" aria-hidden />
            </span>
          </div>
          <div className="bold-text center-text margin-top-20">Custom Wire Selection Guide</div>
        </>
      )}

      <div className="center-text margin-top-10">
        {guide_file ? (
          <>
            <span
              className="underline-text viewable-text file-span"
              onClick={() => {
                props.setModal('ws_pdf', guide_file);
              }}
            >
              Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden /> {guide_file?.upload_data?.indexOf('manual') >= 0 ? '(Manually Uploaded)' : ''}
            </span>
            {rework_in_progress ? <span className="bold-text"> (Rev#: {current_rev})</span> : null}
            <span>
              {' '}
              <i
                className="fa fa-trash-o trash-color"
                aria-hidden
                onClick={() => {
                  props.onRemoveClick('Remove Wire Selection Guide', 'ws_guide', guide_file.upload_data);
                }}
              />
            </span>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-bpp-upload btn-margin-bottom"
              onClick={() => {
                props.onUploadClick('', 'Wire Selection Guide', 'ws_guide', false, current_rev);
              }}
            >
              {`Upload Wire Selection Guide`}
            </button>
            {props.ws_guide_file_name_error && (
              <div className="wire-selection-section__error">
                Incorrect filename
                <br />
                Filename should be: {removeCaseIdInitialNumber(props.case_id)} - Wire Selection Guide Form
              </div>
            )}
          </>
        )}
      </div>

      {sorted_wires.length > 0 ? (
        <div className="center-text margin-top-20">
          <div className="bold-text">Custom Wire</div>
          {sorted_wires.map((wire, i) => (
            <div key={i}>
              <a className="underline-text viewable-text file-span" href={`/${getURLByWireName(translateWiresName(wire), wire)}`} download onClick={onDownload}>
                {translateWiresName(wire)} <i className="fa fa-file-archive-o" aria-hidden />
              </a>
            </div>
          ))}
        </div>
      ) : null}

      <div
        className="link-button center-text margin-top-20"
        onClick={() => {
          props.setModal('go_back');
        }}
      >
        <i className="fa fa-long-arrow-left" aria-hidden /> Return to Wire Selection Review
      </div>
      {guide_file && (
        <div className="button-panel margin-top-20">
          <button
            className="btn btn-light"
            onClick={() => {
              props.setModal('complete_review');
            }}
          >
            Proceed
          </button>
        </div>
      )}
    </div>
  );
}
export { sortWires, getSmartwireNum };
export default WireSelectionReviewConfirm;
