import _ from 'lodash';
import './wire_selection_review_confirm.scss';
import React, { useState, useEffect } from 'react';
import WireSelectionReviewItem from './wire_selection_review_item';
import { onDownload } from '../../../common/functions';

const END_DATE = '2024-03-31T23:59:59';

const grabAllErrorDescription = (desc_obj) => {
  let errors = [];

  if (desc_obj) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(desc_obj)) {
      if (value.length !== 0) {
        errors.push(value);
      }
    }
  }

  return errors;
};
/**
 * Create the data structure needed for the WireSelection section base on the
 * selection
 * @param {Array} selection - List of selections allowed for the WS
 * @param {Object} status_comment_json - Loaded JSON from the output of the WS run
 * @param {Object} universal_arch_info - Universal Arch status info
 * @function
 */
export const parseWSSelections = (selections, status_comment_json, universal_arch_info) => {
  return selections.map((item) => {
    const arch_info = item.wire_name.split('_');
    const arch = arch_info.indexOf('lower') >= 0 ? 'Lower' : 'Upper';
    const universal_arch = universal_arch_info[arch.toLowerCase()];
    const smartwire_num =
      item.wire_name.indexOf('final') >= 0 ? 3 : item.wire_name.indexOf('intermediate') >= 0 ? 2 : item.wire_name.indexOf('initial') >= 0 ? 1 : 0;
    const has_custom_notes = `${item.wire_name}_automated-errors` in status_comment_json;
    const has_result =
      'results-collated' in status_comment_json &&
      'wires' in status_comment_json['results-collated'] &&
      arch_info[1] in status_comment_json['results-collated']['wires'] &&
      arch_info[0] in status_comment_json['results-collated']['wires'][arch_info[1]];

    const error_description =
      status_comment_json[`${item.wire_name}_automated-errors`] &&
      status_comment_json[`${item.wire_name}_automated-errors`].length > 0 &&
      status_comment_json[`${item.wire_name}_automated-errors`][0]['errors'] &&
      status_comment_json[`${item.wire_name}_automated-errors`][0]['errors']['descriptions']
        ? status_comment_json[`${item.wire_name}_automated-errors`][0]['errors']['descriptions']
        : '';

    const custom_notes = has_custom_notes && error_description ? grabAllErrorDescription(error_description) : [];

    const template_notes = has_result
      ? grabAllErrorDescription(status_comment_json['results-collated']['wires'][arch_info[1]][arch_info[0]]['errors']['descriptions'])
      : [];

    const template_name = has_result ? status_comment_json['results-collated']['wires'][arch_info[1]][arch_info[0]]['template'] : 'Unknown';
    const universal_template_recommended = has_result
      ? status_comment_json['results-collated']['wires'][arch_info[1]][arch_info[0]]['universal_template_recommended']
      : false;
    const universal_template_name = has_result && universal_template_recommended ? 'Standard' : 'Unknown';
    const recommendation =
      status_comment_json &&
      status_comment_json['wire-choices'] &&
      status_comment_json['wire-choices'][arch_info[0]] &&
      status_comment_json['wire-choices'][arch_info[0]][arch_info[1]]
        ? status_comment_json['wire-choices'][arch_info[0]][arch_info[1]]
        : null;

    return {
      smartwire_num: smartwire_num,
      type: `Smartwire ${smartwire_num}`,
      arch: arch,
      wire_name: item.wire_name,
      wire_rev: item.wire_rev,
      template_name: template_name,
      universal_template_name: universal_template_name,
      universal_template_recommended: universal_template_recommended,
      template_notes: template_notes,
      custom_notes: custom_notes,
      selected: item.selection_override ? item.selection_override : item.selection,
      previous: item.selection_previous,
      automated_url: item.case_file__upload_data,
      manual_url: item.case_file_manual__upload_data,
      recommendation: custom_notes.length > 0 && template_notes.length > 0 ? 'manual_custom' : recommendation,
      universal_arch: universal_arch,
      pusher_loop: item.pusher_loop,
      w_loop: item.w_loop,
      u_loop: item.u_loop,
    };
  });
};

const getAllowedSelection = (ws, de_selection, arch) => {
  const filtered_selection = ws.filter((item) => (item.wire_name.includes('specialty') ? true : item.selection !== 'none'));

  let ws_de_selection = de_selection.toLowerCase().replace('0', 'specialty').replace('1', 'initial').replace('2', 'intermediate').replace('3', 'final');

  let selection =
    de_selection !== ''
      ? filtered_selection.filter((item) => {
          const arch_info = item.wire_name.split('_');
          return arch_info[1].includes('specialty') || (ws_de_selection.indexOf(arch_info[0]) >= 0 && ws_de_selection.indexOf(arch_info[1]) >= 0);
        })
      : filtered_selection;

  return selection.filter((item) => {
    const arch_info = item.wire_name.split('_');
    return arch === 'both' || arch === arch_info[0];
  });
};

/**
 * Retrieves wires in redesign process
 * @function
 * @param {object} ws - List of wires
 * @returns {list} - List of wires to be redesigned
 */
const getReworkSelection = (ws) => {
  return ws.filter((item) => {
    if (item.status === 'Failure' && item.comment) {
      return item.comment.course_of_action === 'Redesign';
    }
    return false;
  });
};

/**
 * Determines whether an arch should be locked to universal from WS Status JSON
 * @function
 * @param {Object} status_json
 * @returns {Object} unirsal arch statuses
 */
export const assignUniversalArch = (status_json) => {
  const universal_arch_info = {
    upper: false,
    lower: false,
  };
  if (!_.isEmpty(status_json)) {
    const arches = ['upper', 'lower'];
    for (let arch of arches) {
      for (let wire in status_json['wire-choices'][arch]) {
        if (status_json['wire-choices'][arch][wire] === 'universal_template') {
          universal_arch_info[arch] = true;
          break;
        }
      }
    }
  }
  return universal_arch_info;
};

function WireSelectionReviewDetail(props) {
  const [showNewIcon, setShowNewIcon] = useState(false);
  useEffect(() => {
    const today = new Date();
    const endDate = new Date(END_DATE);
    setShowNewIcon(today <= endDate);
  }, []);

  const rework_in_progress = props.wire_fabrication_review_status === 'WFR Rework';
  const allowed_selections = rework_in_progress ? getReworkSelection(props.ws) : getAllowedSelection(props.ws, props.ws_de_selection, props.arch);
  const universal_arch_info = assignUniversalArch(props.ws_status_comment_json);
  const unsort_selections = parseWSSelections(allowed_selections, props.ws_status_comment_json, universal_arch_info);
  const selections = _.orderBy(unsort_selections, ['smartwire_num', 'arch'], ['asc', 'desc']);

  const disabled_generate = selections.filter((wire) => wire.manual_url === null && wire.selected === 'manual_custom');

  const adjust_bmf_file = props.ws_files.findLast((file) => file.file_type === 'ws_bmf' && file.upload_data.includes('.pdf'));
  const adjust_bmf_file_csv = props.ws_files.findLast(
    (file) => file.upload_data.includes(`${props.case_details.case_id}_adjusted_bmf.csv`) && file.file_type === 'ws_bmf_csv'
  );

  const bmf_url = props.bmf_file ? props.bmf_file.url : '';
  const bmf_csv = adjust_bmf_file_csv ? adjust_bmf_file_csv.upload_data : '';
  const bmf_rev = props.bmf_file ? props.bmf_file.bmf_rev : 'N/A';

  const [wire_change_error, setWireChangeError] = useState(false);

  /**
   * Determines if wire selections are different from previous rework
   * @function
   * @returns {boolean} - True or false
   */
  const validateReworkWires = () => {
    if (props.wire_fabrication_review_status === 'WFR Rework') {
      for (const selection of selections) {
        if (selection.selected !== 'manual_custom') {
          const prev_ws = props.ws_prev_rev.find((wire) => wire.wire_name === selection.wire_name);
          if (prev_ws) {
            const prev_selection = prev_ws.selection_override ? prev_ws.selection_override : prev_ws.selection;
            if (prev_selection === selection.selected) {
              setWireChangeError(true);
              return;
            }
          }
        }
      }
    }
    props.setModal('generate');
  };

  return (
    <div className="wire-selection-section">
      <div className="bold-text center-text margin-top-20">
        Wire Selection Review{props.case_details.ws_rework_no ? ` (Rework ${props.case_details.ws_rework_no})` : ''}
      </div>
      <div className="center-text">
        Please review recommended custom wire(s) below and make updates as needed. Click <b>Generate</b> to generate the Wire Selection Guide.
      </div>

      {bmf_url && (
        <div className="bmf-link">
          <a className="underline-text viewable-text view-upload" href={`/${bmf_url}`} download onClick={onDownload}>
            Bracket Measurements Form <i className="fa fa-file-excel-o" aria-hidden="true" />
          </a>
          <span>
            (<span className="bold-text">Rev#: </span>
            {bmf_rev})
          </span>
        </div>
      )}
      <div className="center-text">
        <span
          className="underline-text viewable-text file-span"
          onClick={() => {
            props.setModal('bmf_pdf', adjust_bmf_file);
          }}
        >
          Adjusted Bracket Measurements Form <i className="fa fa-file-pdf-o" aria-hidden="true" />
        </span>
      </div>
      {bmf_csv && (
        <div className="center-text">
          <a className="underline-text viewable-text file-span csv-link" href={`/${bmf_csv}`} download onClick={onDownload}>
            Download ABMF CSV
          </a>
          <i className="fa fa-file-excel-o csv-icon-file" aria-hidden="true" />
          {showNewIcon && <span className="new-icon"> New!</span>}
        </div>
      )}
      <div>
        {selections.map((wire, i) => (
          <WireSelectionReviewItem
            key={i}
            rev={wire.wire_rev}
            caseId={props.case_id}
            wire={wire}
            onChange={(selection) => {
              setWireChangeError(false);
              props.wsSelect(selection, wire.wire_name);
            }}
            wsToggleSpecial={props.wsToggleSpecial}
            ws_programs={props.ws_programs}
            ws_status_comment_json={props.ws_status_comment_json}
            onUploadClick={props.onUploadClick}
            onRemoveClick={props.onRemoveClick}
            show_manual_error={wire.manual_url === null && wire.selected === 'manual_custom'}
            rework_in_progress={rework_in_progress}
          />
        ))}
      </div>
      {disabled_generate.length !== 0 && <div className="center-text warning-text">Please update the DXF file for the selected manual custom wire.</div>}
      {wire_change_error && <div className="center-text warning-text">Please update wire selections.</div>}
      <div className="button-panel margin-top-20">
        <button className="btn btn-light" onClick={validateReworkWires} disabled={disabled_generate.length > 0}>
          Generate
        </button>
      </div>
    </div>
  );
}

export default WireSelectionReviewDetail;
